import { Typography } from "@mui/material";
import React from "react";
import FlexBoxColumn from "../../../common/box/FlexBoxColumn";
import CustomList from "../../../common/list/CustomList";
import CustomListItem from "../../../common/list/CustomListItem";

const ETLItems = [
    {
        title: "Connectors",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="JDBC" description="Retrieve data directly from a MySQL Database using predicates to improve performance" />
                    <CustomListItem title="Redshift" description="Retrieve and upload data with Redshift connector using pre-sql and post-sql statements" />
                    <CustomListItem title="JSON/CSV" description="Spark inner function .json() and .csv() for batch and streaming" />
                    <CustomListItem title="Delta" description="Good knowledge on Delta Table usages and format (Snappy/Parquet)" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "Spark Transformation",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Ingest raw data" description="Basic 1 to 1 data gathering (bronze layer) with algorithms like SCD2" />
                    <CustomListItem title="Transformations" description="Custom transformations simple or complex to add new columns using functions such windowing, partitioning, filtering, ... (silver layer)" />
                    <CustomListItem title="End-user tables" description="Aggregations and filtering in business tables (gold layer)" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "Investigations",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Spark UI" description="Analysis of different KPIs given by the UI (Spill, Sizes, Tasks, Stages...)" />
                    <CustomListItem title="SQL Plan" description="Analysis of the SQL plans to help in performance struggles." />
                    <CustomListItem title="Custom Listener" description="Add some custom class to add specific metrics to help to debug or monitor" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "And a lot more !",
        children:
            <FlexBoxColumn>
                <Typography variant="h6" fontWeight="bold" color="primary.contrastText" align="center">
                    <br />
                    Don't hesitate to ask if you need something not specified here !
                </Typography>
            </FlexBoxColumn>
    }
]

export default ETLItems;