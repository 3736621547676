import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import FlexBoxColumn from "../../../common/box/FlexBoxColumn";
import CustomList from "../../../common/list/CustomList";
import CustomListItem from "../../../common/list/CustomListItem";

const AWSItems = [
    {
        title: "AWS S3",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Security" description="ACL, BucketOwner/ObjectOwner, Encryption(SSE-3) with KMS Managed by the user, Policies" />
                    <CustomListItem title="Storage" description="Any files, Versioning, Metadata, Lifecycle Policies, Storage Classes" />
                    <CustomListItem title="Integration" description="Event Notifications by S3 or using Bridge" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "AWS Glue",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Data Governance" description="Data Catalog, Crawlers, Visualisation of the jobs, Use the catalog with Athena and Spectrum" />
                    <CustomListItem title="Security" description="IAM, KMS on Data and on the Catalog" />
                    <CustomListItem title="Workflow" description="Spark and Python Jobs, Scheduling, Triggering with events" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "AWS Network and Security",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Network" description="VPC, Security Group, Subnets, Internet Gateway" />
                    <CustomListItem title="IAM" description="IAM Groups, Instance Profiles, Policies, Roles" />
                    <CustomListItem title="Monitoring" description="Every actions monitored by CloudWatch" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "AWS Compute",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="EC2" description="GPU to CPU nodes, wide variety of size" />
                    <CustomListItem title="Network" description="A dedicated VPC, Instance Connect with SSH" />
                    <CustomListItem title="FinOps" description="Autotermination, Spot Market, ELB" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "AWS Redshift",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Optimization" description="Queues, Distribution Keys, Storage Format (compression), Resizing of the cluster" />
                    <CustomListItem title="Good practices" description="Different users/groups, Name convention for all objects" />
                    <CustomListItem title="Integration" description="Use with Databricks and S3, connect to Tableau" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "AWS Lambda",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Core" description="Python projects, Layers for reuse, Packaging of a Lambda" />
                    <CustomListItem title="Security" description="Specific IAM Role, KMS, Logs Monitoring with CloudWatch" />
                    <CustomListItem title="Integration" description="Trigger an API async call, triggered by events (Datasync, S3...)" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "AWS Snowcone & Datasync",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Snowcone" description="Lock, Unlock, Use with OpsHub, Commands" />
                    <CustomListItem title="Datasync" description="Schedule a job to synchronize a Snowcone with S3" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "And more !",
        children:
            <FlexBoxColumn>
                <Typography variant="h6" fontWeight="bold" color="primary.contrastText" align="center">
                    <br />
                    Don't hesitate to ask if you need something not specified !
                </Typography>
            </FlexBoxColumn>
    }
]

export default AWSItems;