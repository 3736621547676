import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const MyBox = styled(Box)(({ theme }) => ({
    display: 'none',
    flexGrow: 0,
    [theme.breakpoints.down("lg")]: {
        display: 'flex'
    }
}))


const XSNavBox = (props: BoxProps) => {
    const { children, ...otherprops } = props
    return (
        <MyBox {...otherprops}>
            {children}
        </MyBox>
    );
};

export default XSNavBox;