import { Box, styled, Typography } from "@mui/material";
import React from "react";

const StyledTitleBox = styled(Box)(({ theme }) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '2vh',
    flexGrow: 0,
}))

const AboutMeTitle = () => {
    return (
        <StyledTitleBox>
            <Typography variant="h2" sx={{
                fontWeight: 'bold'
            }}>
                About Me
            </Typography>
        </StyledTitleBox>
    )
}

export default AboutMeTitle;