import React from 'react';
import './App.css';
import Home from './components/home/Home';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from './store';
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavProvider from './contexts/NavContext';

class App extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.setState({ loading: false })
  }

  render() {
    const { loading } = this.state;

    if (loading) { // if your component doesn't have to wait for an async action, remove this block 
      return null; // render null when app is not ready
    }

    return (
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <NavProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
            </BrowserRouter>
          </NavProvider>
        </PersistGate>
      </Provider>
    );
  }

}

export default App;