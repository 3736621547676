import React from "react";
import { List, styled } from "@mui/material";

const CustomList = styled(List)(({ theme }) => ({
    listStyleType: 'disc',
    pl: 2,
    '& .MuiListItem-root': {
        display: 'list-item',
    },
    flexGrow: 1,
    display: "flex",
    flexDirection: 'column'
}))

export default CustomList;
