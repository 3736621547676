import React from "react";

import { Typography } from "@mui/material";
import FlexBoxColumn from "../../../common/box/FlexBoxColumn";

const CreativeLearnerItems = [
    {
        title: "Optimization problems",
        children:
            <FlexBoxColumn>
                <Typography variant="h6" color="primary.contrastText" align="center">
                    <br />
                    I love to face performance issues because it needs most of the time to deeply understand the subjects. I have already solved issues on Python, Spark, Java, Oracle DB, Redshift and more...
                </Typography>
            </FlexBoxColumn >
    },
    {
        title: "Architecture Design",
        children:
            <FlexBoxColumn>
                <Typography variant="h6" color="primary.contrastText" align="center">
                    <br />
                    I really enjoy designing solutions integrating multiple tools. Each context has its specificity and this is what makes it challenging !
                </Typography>
            </FlexBoxColumn>
    },
    {
        title: "POC new solutions",
        children:
            <FlexBoxColumn>
                <Typography variant="h6" color="primary.contrastText" align="center">
                    <br />
                    Building POCs to test the feasibility and challenge different solutions with other experts.
                </Typography>
            </FlexBoxColumn >
    }
]

export default CreativeLearnerItems;