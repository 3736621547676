import React from "react";
import { styled, Box } from "@mui/material";
import { useNav } from "../../../hooks/useNav";
import PublicationsTitle from "./PublicationsTitle";
import PublicationsBox from "./PublicationsBox";

const CustomBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("lg")]: {
        paddingTop: '8vh',
        paddingLeft: '5vw',
        paddingBottom: '3vh',
        paddingRight: '5vw'
    },
    display: 'flex',
    flexDirection: 'column'
}))

const Publications = () => {
    const publicationsRef = useNav("Publications")

    return (
        <CustomBox ref={publicationsRef} id="publications" >
            <PublicationsTitle />
            <PublicationsBox />
        </CustomBox>
    )
}

export default Publications;