import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { RandomReveal } from 'react-random-reveal';


const TopLineTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Source Code Pro',
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold'
}))

const LinesTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Source Code Pro',
    color: theme.palette.primary.contrastText,
    letterSpacing: '.005rem',
    width: '80%'
}))

const BoxContainer = styled(Box)(({ theme }) => ({
    flexDirection: 'column',
    [theme.breakpoints.up("xl")]: {
        width: '50%'
    },
    [theme.breakpoints.between("md", "xl")]: {
        width: '75%'
    }
}))


function AnimatedText() {
    return (
        <BoxContainer >
            <TopLineTypo variant='h2'>
                <RandomReveal
                    isPlaying
                    duration={4}
                    revealDuration={1.6}
                    characters="Cloud Data Engineer"
                    onComplete={() => ({ shouldRepeat: false })}
                    revealEasing="easeOutQuad"
                />
            </TopLineTypo>

            <br />

            <LinesTypo variant='h5'>
                <RandomReveal
                    isPlaying
                    duration={4}
                    revealDuration={1.6}
                    characters="Welcome to my website, I am here to help you to achieve Data-Driven success with Cloud, Spark and Python Technologies."
                    onComplete={() => ({ shouldRepeat: false })}
                    revealEasing="easeOutQuad"
                />
            </LinesTypo>


        </BoxContainer >
    )
}

export default AnimatedText;
