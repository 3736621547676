import React from "react";
import { styled, Box } from "@mui/material";
import SkillsTitle from "./SkillsTitle";
import { useNav } from "../../../hooks/useNav";
import SkillsBox from "./SkillsBox";

const CustomBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("lg")]: {
        paddingTop: '8vh',
        paddingLeft: '5vw',
        paddingBottom: '3vh',
        paddingRight: '5vw'
    },
    display: 'flex',
    flexDirection: 'column'
}))

const Skills = () => {
    const aboutMeRef = useNav("Skills")

    return (
        <CustomBox ref={aboutMeRef} id="skills" >
            <SkillsTitle />
            <SkillsBox />
        </CustomBox>
    )
}

export default Skills;