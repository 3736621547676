import React from "react";
import { styled, Box, Typography, List, ListItem, Tooltip, Zoom } from "@mui/material";
import { ReactComponent as LogoEducation } from '../../../assets/images/education.svg';
import CustomListItemTooltip from "./CustomListItemTooltip";

const StyledBox = styled(Box)(({ theme }) => ({
    padding: '2vh',
    [theme.breakpoints.down("lg")]: {
        display: "none",
        flexGrow: 0
    }
}))

const BigDataTraining = () => {
    return (
        <Box>
            <List disablePadding>
                <ListItem>Structure of a Big Data project</ListItem>
                <ListItem>Visualization tools</ListItem>
                <ListItem>AWS Services</ListItem>
            </List>
        </Box>
    )
}

const DeepLearningSpecialization = () => {
    return (
        <Box>
            <List disablePadding>
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/verify/LE22AL67M286" description="Neural Networks and Deep Learning" />
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/verify/PMLGUB973MFN" description="Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization" />
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/verify/L2E2V3VBH7CF" description="Structuring ML Projects" />
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/verify/F7YRXZ4LM2ZX" description="CNN" />
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/verify/FBSFAAX55YCF" description="Sequence Models" />
            </List>
        </Box>
    )
}

const SparkDatabricksTrainings = () => {
    return (
        <Box>
            <List disablePadding>
                <ListItem>Data Engineer</ListItem>
                <ListItem>Apache Spark</ListItem>
                <ListItem>Platform Admin</ListItem>
            </List>
        </Box>
    )
}

const ScalaSparkTraining = () => {
    return (
        <Box>
            <List disablePadding>
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/certificate/7HE7KR96U7QM" description="Functional Programming Principles in Scala" />
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/certificate/KKBWYYTXV767" description="Functional Program Design in Scala" />
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/certificate/X7SS4PSWXTLW" description="Parallel Programming" />
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/certificate/A7KNMTVZUXMG" description="Big Data analysis with Scala and Spark" />
                <CustomListItemTooltip link="https://www.coursera.org/account/accomplishments/certificate/5R8PT5GHARN" description="Capstone Project (world temperature prediction)" />
            </List>
        </Box>
    )
}

const BoxLogo = () => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "1vh"
        }}>
            <LogoEducation width={"50px"} height={"50px"} />
        </Box>
    )
}

const StyledTypographyYear = styled(Typography)(() => ({
    alignSelf: "left",
    fontStyle: "italic",
    fontWeight: "bold"
}))

const StyledTypographyLocation = styled(Typography)(() => ({
    fontWeight: 300,
    fontStyle: "italic",
}))


const BoxPolytechGrenoble = () => {
    return (
        <Box display={"flex"}>
            <Box width={"20%"}>
                <StyledTypographyYear variant="h6">
                    2015
                </StyledTypographyYear>
            </Box>
            <Box>
                <Typography variant="h6">
                    Computer Engineer At Polytech' Grenoble School
                </Typography>
            </Box>
        </Box>
    )
}

const BoxDeepLearningSpecialization = () => {
    return (
        <Box display={"flex"} sx={{
            paddingTop: "5vh"
        }}>
            <Box display={"flex"} flexDirection={"column"} width={"20%"}>
                <StyledTypographyYear variant="h6">
                    2019
                </StyledTypographyYear>
                <StyledTypographyLocation variant="h6" >
                    Coursera
                </StyledTypographyLocation>
            </Box>
            <Box>
                <Tooltip TransitionComponent={Zoom} title={<DeepLearningSpecialization />}>
                    <Typography variant="h6">
                        Deep Learning specialization
                    </Typography>
                </Tooltip>
                <Typography variant="body2" fontStyle={"italic"}>
                    Hover for details
                </Typography>
            </Box>
        </Box>
    )
}

const BoxBigDataTraining = () => {
    return (
        <Box display={"flex"} sx={{
            paddingTop: "5vh"
        }}>
            <Box display={"flex"} flexDirection={"column"} sx={{ width: "20%" }}>
                <StyledTypographyYear variant="h6">
                    2019
                </StyledTypographyYear>
                <StyledTypographyLocation variant="h6">
                    Paris
                </StyledTypographyLocation>
            </Box>
            <Box>
                <Tooltip TransitionComponent={Zoom} title={<BigDataTraining />}>
                    <Typography variant="h6">
                        Big Data Training
                    </Typography>
                </Tooltip>
                <Typography variant="body2" fontStyle={"italic"}>
                    Hover for details
                </Typography>
            </Box>
        </Box>
    )
}

const BoxSparkScalaTraining = () => {
    return (
        <Box display={"flex"} sx={{
            paddingTop: "5vh"
        }}>
            <Box display={"flex"} flexDirection={"column"} sx={{ width: "20%" }}>
                <StyledTypographyYear variant="h6">
                    2020
                </StyledTypographyYear>
                <StyledTypographyLocation variant="h6">
                    Coursera
                </StyledTypographyLocation>
            </Box>
            <Box>
                <Tooltip TransitionComponent={Zoom} title={<ScalaSparkTraining />}>
                    <Typography variant="h6">
                        Scala / Spark Training
                    </Typography>
                </Tooltip>
                <Typography variant="body2" fontStyle={"italic"}>
                    Hover for details
                </Typography>
            </Box>
        </Box>
    )
}

const BoxSparkDatabricksTraining = () => {
    return (
        <Box display={"flex"} sx={{
            paddingTop: "5vh"
        }}>
            <Box display={"flex"} flexDirection={"column"} sx={{ width: "20%" }}>
                <StyledTypographyYear variant="h6">
                    2022
                </StyledTypographyYear>
                <StyledTypographyLocation variant="h6">
                    Databricks
                </StyledTypographyLocation>
            </Box>
            <Box>
                <Tooltip TransitionComponent={Zoom} title={<SparkDatabricksTrainings />}>
                    < Typography variant="h6">
                        Spark / Databricks Trainings
                    </Typography>
                </Tooltip>
                <Typography variant="body2" fontStyle={"italic"}>
                    Hover for details
                </Typography>
            </Box>
        </Box>
    )
}


const AboutMeEducation = () => {
    return (
        <StyledBox>
            <BoxLogo />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
            }}>
                <BoxPolytechGrenoble />
                <BoxDeepLearningSpecialization />
                <BoxBigDataTraining />
                <BoxSparkScalaTraining />
                <BoxSparkDatabricksTraining />
            </Box>
        </StyledBox >
    )
}

export default AboutMeEducation;