import React from "react";
import { styled, Modal, Box } from "@mui/material";
import { ModalProps } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    border: `4px solid ${theme.palette.primary.contrastText}`,
    borderRadius: '15px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '24',
    height: '60vh',
    width: '50vw',
    display: "flex",
    flexGrow: 1
}))

function CommonModal(props: ModalProps) {
    const { children, ...rest } = props

    return (
        <Modal {...rest} sx={{
            display: 'flex'
        }}>
            <StyledBox>
                {children}
            </StyledBox>

        </Modal>

    )
}

export default CommonModal;