import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import React from "react";

const PublicationsBox = () => {
    return (
        <Box sx={{
            paddingTop: "3vh",
            display: "flex",
            flexDirection: "column"
        }}>
            <Typography variant="h5" color="primary.contrastText">
                Medium:
            </Typography>
            <List>
                <ListItem>
                    <ListItemButton component="a" href="https://medium.com/@clerc.gherardi/localstack-in-a-pyspark-project-with-ci-54d883763e32">
                        <ListItemText primaryTypographyProps={{
                            color: "primary.contrastText"
                        }} primary="LocalStack in a PySpark project with CI" />
                    </ListItemButton>

                </ListItem>
            </List>

        </Box>
    )
}

export default PublicationsBox;