import React from "react";
import { styled, Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import FlexBoxColumn from "../../common/box/FlexBoxColumn";
import Navya from "./Navya";
import Enedis from "./Enedis";
import STMicroelectronics from "./STMicroelectronics";

const StyledBox = styled(Box)(({ theme }) => ({
    padding: '3vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}))

const ExperiencesCarousel = () => {
    return (
        <StyledBox>
            <Carousel animation="slide"
                swipe={false}
                navButtonsAlwaysVisible
                autoPlay={false}
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: 'column',
                    margin: "5px",
                    width: "100%"
                }}>
                <Navya />
                <Enedis />
                <STMicroelectronics />
            </Carousel>
        </StyledBox>
    )
}

export default ExperiencesCarousel;
