import React from "react";
import { styled, Button, Typography } from "@mui/material";
import { ButtonProps } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    ':hover': {
        backgroundColor: theme.palette.primary.light
    },
    [theme.breakpoints.down("lg")]: {
        display: 'none'
    }
}))

function InfoButton(props: ButtonProps) {
    return (
        <StyledButton variant="outlined" {...props}>
            <Typography variant="body1" fontWeight="bold" color="primary.main">
                More info
            </Typography>
        </StyledButton>
    )
}

export default InfoButton;