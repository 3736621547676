import { Avatar, Box, Button, Collapse, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import FlexBoxColumn from "../../common/box/FlexBoxColumn";
import SeparatorBox from "../../common/box/SeparatorBox";
import CommonModal from "../../common/modal/CommonModal";

const STMicroelectronics = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <FlexBoxColumn sx={{
            justifyContent: "center",
        }}>
            <SeparatorBox sx={{ marginBottom: "10px", borderColor: "secondary.main" }} />
            <Box sx={{
                display: "flex",
                flexGrow: 0,
                flexDirection: "row",
                justifyContent: "space-evenly"
            }}>
                <Typography variant="h5" align="center" fontWeight="bold">
                    STMicroelectronics (3 years, 2017-2020), Crolles
                </Typography>
                <Button onClick={handleOpen}>
                    Click for Technical Stack
                </Button>
                <CommonModal open={open}
                    onClose={handleClose}>
                    <FlexBoxColumn sx={{
                        padding: '20px'
                    }}>
                        <Typography variant="h5" color="primary.contrastText" align="center">
                            Technical Stack
                        </Typography>
                        <List sx={{
                            paddingLeft: "3vw",
                            listStyleType: 'disc',
                            pl: 2
                        }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Java 1.6 & Java 1.8
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Java libraries: JEE, Primefaces
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    OS: Redhat, Windows
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Java container: Wildfly 12
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Database: Oracle 10g
                                </Typography>
                            </ListItem>
                        </List>
                    </FlexBoxColumn>
                </CommonModal>
            </Box>

            <SeparatorBox sx={{ marginTop: "10px", borderColor: "secondary.main" }} />
            <FlexBoxColumn sx={{
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                width: "85%"
            }}>
                <FlexBoxColumn sx={{
                    paddingTop: "5vh"
                }}>
                    <Typography variant="h6" align="left">
                        <span style={{ fontWeight: 'bold' }}>Context</span> : Industrial computing in contact with the customer. Hired in the reporting team, I quickly evolved to work in the team that managed the automation & optimization of robots in clean room.
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column"
                    }}>
                        <Typography variant="h6" align="left" fontWeight={"bold"} sx={{ paddingTop: "10px" }}>
                            Missions:
                        </Typography>
                        <List sx={{
                            paddingLeft: "3vw",
                            listStyleType: 'disc',
                            pl: 2
                        }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Development of a JAVA 1.8 / JEE application for the management of consumables on production equipment (optimization and display). Use of shared cache and redundancy for servers used 24/7
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Development of preprocessing / postprocessing in JAVA 1.6 around an optimization engine
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Proposals for technical and business specifications
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Creation of JAVA 1.8 utility jars
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Feasibility study of BLE devices and industrial Windows 10 tablets
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Creation / Use of AMAT RTD reports
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    PL/SQL batch development on Oracle 10g
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Migration from DB Oracle 6i to 10g
                                </Typography>
                            </ListItem>
                        </List>
                    </Box>


                </FlexBoxColumn>
            </FlexBoxColumn>
        </FlexBoxColumn>
    )
}

export default STMicroelectronics;