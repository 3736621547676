import React from "react";
import { styled, Box } from "@mui/material";

const FlexBoxColumn = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    width: '100%'
}))

export default FlexBoxColumn;