import React from 'react';
import BackgroundContainer from './background/Container';
import TopBar from './top_bar/TopBar';
import Footer from '../common/Footer';
import Headlines from './headlines/Headlines';
import AboutMe from './about_me/AboutMe';
import Skills from './skills/Skills';
import Experiences from './experiences/Experiences';
import Publications from './publications/Publications';
import Contact from './contact/Contact';


function Home() {

    return (
        <>
            <TopBar />
            <BackgroundContainer >
                {/* <TopBar / > */}
                < Headlines />
            </BackgroundContainer>
            <AboutMe />
            <Skills />
            <Experiences />
            <Publications />
            <Contact />
            <Footer />
        </>
    )
}

export default Home;