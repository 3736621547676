import React from 'react';
import { Box } from '@mui/system';
import AnimatedText from './AnimatedText';
import { styled } from '@mui/material/styles';


const CustomHeadlinesBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    paddingTop: '5vh',
    [theme.breakpoints.down("md")]: {
        paddingTop: '2vh'
    },
    paddingLeft: '5vw',
    display: 'flex'
}))

const Headlines = () => {
    return (
        <CustomHeadlinesBox>
            <AnimatedText />
        </CustomHeadlinesBox>
    )
}

export default Headlines;