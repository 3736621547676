import React from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import { NavContext } from '../../../contexts/NavContext';
import { useContext } from 'react';
import { scrollWithOffset } from '../../../functions/scroll';

interface Props extends MenuItemProps {
    handleCloseNavMenu?: () => void;
    navLinkId: string,
    scrollToId: string
}

const XSNavItems = ({ navLinkId, scrollToId, handleCloseNavMenu, ...rest }: Props) => {

    const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);

    const handleClick = () => {
        if (handleCloseNavMenu)
            handleCloseNavMenu()
        setActiveNavLinkId(navLinkId)

        const elementToScroll = document.getElementById(scrollToId) as HTMLElement;
        scrollWithOffset(elementToScroll, 50)
    }

    return (
        <MenuItem {...rest} id={navLinkId} className={activeNavLinkId === navLinkId ? 'activeClass' : ''} onClick={handleClick}>
            {navLinkId}
        </MenuItem>
    )
}

export default XSNavItems;