const orange = {
    50: "#FFF3E0",
    100: "#FFE0B2",
    200: "#FFCC80",
    300: "#FFB74D",
    400: "#FFA726",
    500: "#FF9800",
    600: "#FB8C00",
    700: "#F57C00",
    800: "#00410b",
    900: "#EF6C00"
};

export const topBarOrange = "#dd6b27"
export const lightBackgroundOrange = "#fbf2ea"

export default orange;