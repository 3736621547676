import React, { useContext } from 'react';
import AdbIcon from '@mui/icons-material/Adb';
import svgLogo from '../../../assets/images/raccoon.svg';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { NavContext } from '../../../contexts/NavContext';
import { navLinks } from './navLinks';
import { scrollWithOffset } from '../../../functions/scroll';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    flexGrow: 0,
    display: "flex",
    marginRight: 1,
    marginLeft: 1,
    [theme.breakpoints.down("lg")]: {
        display: 'none'
    },
    alignItems: 'center'
}))

const StyledAdbIcon = styled(AdbIcon)(({ theme }) => ({
    color: theme.palette.primary.contrastText
}))

const Logo = () => {

    const { setActiveNavLinkId } = useContext(NavContext);

    const { navLinkId, scrollToId } = navLinks[0]

    const handleClick = () => {
        setActiveNavLinkId(navLinkId)

        const elementToScroll = document.getElementById(scrollToId) as HTMLElement;

        scrollWithOffset(elementToScroll, 50)
    }

    return (
        <StyledIconButton onClick={handleClick}>
            <div>
                <img src={svgLogo} style={{ height: '6vh' }} />
            </div>
        </StyledIconButton>

    )
}




export default Logo;