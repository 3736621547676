import React from "react";
import { ListItem, ListItemProps, Typography, styled } from "@mui/material";

interface Props extends ListItemProps {
    title: string,
    description: string
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
    color: theme.palette.primary.contrastText
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily
}))

const CustomListItem = ({ title, description, ...rest }: Props) => {
    return (
        <StyledListItem {...rest}>
            <StyledTypography variant="body1">
                {title}
            </StyledTypography>
            <StyledTypography variant="body1">
                {description}
            </StyledTypography>
        </StyledListItem>
    )
}

export default CustomListItem;