import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { styled, Typography } from "@mui/material";

interface Props {
    icon: React.ReactNode,
    title?: string,
    children?: React.ReactNode
}

const borderSize = "2px"

const MainContainer = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    borderLeft: `${borderSize} solid ${theme.palette.primary.contrastText}`,
    borderBottom: `${borderSize} solid ${theme.palette.primary.contrastText}`,
    borderRight: `${borderSize} solid ${theme.palette.primary.contrastText}`,
    borderRadius: "5px",
    flexGrow: 1,
    margin: "1em"
}))

const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: "row",
    width: "100% !important"
}))

const HeaderBorderBefore = styled('div')(({ theme }) => ({
    borderTop: `${borderSize} solid ${theme.palette.primary.contrastText}`,
    width: '1em',
    flexGrow: 2,
    borderTopLeftRadius: '5px'
}))

const HeaderTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    gap: '0.25em',
    margin: '-1em 0.5em 0em 0.5em',
    height: '3em',
    width: 'fit-content'
}))

const HeaderBorderAfter = styled('div')(({ theme }) => ({
    borderTop: `${borderSize} solid ${theme.palette.primary.contrastText}`,
    width: '1em',
    flexGrow: 2,
    borderTopRightRadius: '5px'
}))

const Children = styled('div')(() => ({
    paddingTop: '2em',
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingBottom: '0.5em',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1
}))




const BorderedSection = ({ icon, title, children }: Props) => {

    return (
        <MainContainer>
            <Header>
                <HeaderBorderBefore />
                <HeaderTitle>
                    {icon}
                    {title &&
                        <Typography variant='h5' color='primary.contrastText'>
                            {title}
                        </Typography>}
                </HeaderTitle>

                <HeaderBorderAfter />
            </Header>
            <Children>
                {children}
            </Children>
        </MainContainer>
    )
}

export default BorderedSection;