import React from "react";
import { Box, styled, Typography } from "@mui/material";

const StyledPublicationsBox = styled(Box)(({ theme }) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '2vh',
    flexGrow: 0,
}))

const PublicationsTitle = () => {
    return (
        <StyledPublicationsBox>
            <Typography variant="h2" sx={{
                fontWeight: 'bold',
                color: "primary.contrastText"
            }}>
                Publications
            </Typography>
        </StyledPublicationsBox>
    )
}

export default PublicationsTitle;