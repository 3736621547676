import React from 'react';
import { Box } from "@mui/material";
import { useNav } from '../../../hooks/useNav';
import './Container.scss';

type Props = {
    children?: React.ReactNode
}

function BackgroundContainer({ children }: Props) {

    const landingPageRef = useNav('Home')

    return (
        <div className='container' ref={landingPageRef} id='home'>
            <Box sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
            }}>
                {children}
            </Box>

        </div>
    )
}

export default BackgroundContainer;