import React from "react";
import { styled, Box, Typography, Button } from "@mui/material";
import { ReactComponent as LogoPython } from '../../../assets/images/python-icon.svg';
import BorderedSection from "../../common/box/BorderedSection/BorderedSection";
import InfoButton from "./InfoButton";

const SkillsPython = () => {
    return (
        <BorderedSection icon={<LogoPython width={'35px'} />} title="Python">
            <Typography variant="h6" color="primary.contrastText" fontWeight="bold" align="center" sx={{
            }}>
                An experienced developer <br /><br />
            </Typography>
            <Typography variant="body1" color="primary.contrastText" align="justify" sx={{
            }}>
                My experiences led me to use different languages like Python and Java. I know frameworks and libraries on the web as well as on data science or data transformation. <br /><br />
            </Typography>

        </BorderedSection>

    )
}

export default SkillsPython;