import React from "react";
import { styled, Box } from "@mui/material";

const SeparatorBox = styled(Box)(({ theme }) => ({
    flexGrow: 0,
    width: '75%',
    borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
    alignSelf: 'center'
}))

export default SeparatorBox;