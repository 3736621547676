import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import React from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const ContactBox = () => {
    return (
        <Box sx={{
            paddingTop: "5vh",
            paddingBottom: "10vh",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly"
        }}>
            <Box display="flex" flexDirection={"column"} sx={{
                justifyContent: "center",
                alignItems: "center"
            }}>
                <InstagramIcon sx={{
                    fontSize: "150px"
                }} />
                <Typography variant="h4">
                    @acg.draw
                </Typography>
            </Box>

            <Box display="flex" flexDirection={"column"} sx={{
                justifyContent: "center",
                alignItems: "center"
            }}>
                <PhoneIcon sx={{
                    fontSize: "150px"
                }} />
                <Typography variant="h4">
                    06.16.16.58.52
                </Typography>
            </Box>

            <Box display="flex" flexDirection={"column"} sx={{
                justifyContent: "center",
                alignItems: "center"
            }}>
                <EmailIcon sx={{
                    fontSize: "150px"
                }} />
                <Typography variant="h4">
                    arthur@clerc-gherardi.fr
                </Typography>
            </Box>


        </Box>
    )
}

export default ContactBox;