import React from 'react';
import { Box } from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNav } from '../../../hooks/useNav';
import AboutMeTitle from './AboutMeTitle';
import AboutMePresentation from './AboutMePresentation';

const CustomBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up("lg")]: {
        paddingTop: '10vh'
    }
}))

const AboutMe = () => {

    const aboutMeRef = useNav("About Me")

    return (
        <CustomBox ref={aboutMeRef} id="aboutMe">
            <AboutMeTitle />
            <AboutMePresentation />
        </CustomBox>
    )
}



export default AboutMe;
