import React from "react";
import { Grid } from "@mui/material";
import SkillsPython from "./SkillsPython";
import SkillsSpark from "./SkillsSpark";
import SkillsDatabricks from "./SkillsDatabricks";
import SkillsAWS from "./SkillsAWS";
import SkillsAutonomy from "./SkillsCreative";
import SkillsSupport from "./SkillsSupport";
import SkillsIntegration from "./SkillsIntegration";


const SkillsBox = () => {
    return (
        <Grid container direction="row" spacing={2} alignItems="stretch"
            justifyContent="center" display="flex">
            <Grid item md={6} lg={4} flexGrow={1} display="flex">
                <SkillsPython />
            </Grid>
            <Grid item md={6} lg={4} flexGrow={1} display="flex">
                <SkillsSpark />
            </Grid>
            <Grid item md={6} lg={4} flexGrow={1} display="flex">
                <SkillsDatabricks />
            </Grid>
            <Grid item md={6} lg={4} flexGrow={1} display="flex">
                <SkillsAWS />
            </Grid>
            <Grid item md={6} lg={4} flexGrow={1} display="flex">
                <SkillsAutonomy />
            </Grid>
            <Grid item md={6} lg={4} flexGrow={1} display="flex">
                <SkillsIntegration />
            </Grid>
            <Grid item md={6} lg={4} flexGrow={1} display="flex">
                <SkillsSupport />
            </Grid>
        </Grid>


    )
}

export default SkillsBox;