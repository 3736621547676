import React from 'react';
import { Box } from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNav } from '../../../hooks/useNav';
import ContactTitle from './ContactTitle';
import ContactBox from './ContactBox';

const CustomBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up("lg")]: {
        paddingTop: '10vh'
    }
}))

const Contact = () => {
    const contactRef = useNav("Contact")

    return (
        <CustomBox ref={contactRef} id="contact">
            <ContactTitle />
            <ContactBox />
        </CustomBox>
    )
}



export default Contact;
