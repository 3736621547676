import React from "react";
import { styled, Box, Typography } from "@mui/material";
import { ReactComponent as LogoHuman } from '../../../assets/images/human.svg';

const StyledBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    padding: '2vh'
}))

const AboutMeText = () => {
    return (
        <StyledBox>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "1vh"
            }}>
                <LogoHuman width={"50px"} height={"50px"} />
            </Box>
            <Typography variant='h6'>
                <span style={{ fontWeight: 'bold' }}>Firstname</span>: Arthur
            </Typography>
            <Typography variant='h6'>
                <span style={{ fontWeight: 'bold' }}>Lastname</span>: Clerc-Gherardi
            </Typography>
            <Typography variant='h6'>
                <span style={{ fontWeight: 'bold' }}>Age</span>: 30
            </Typography>
            <br />
            <Typography variant='h6' align='justify' paragraph={true}>
                I'm a passionate developer about computer science and new technologies. <br /> <br />
                After starting my career as a backend developer, I switched to the subject I was most passionate about, Data.
                <br />
                <br />
                Today, I help the companies I work with to scale their data context using Spark and the Cloud.
            </Typography>
        </StyledBox>
    )
}

export default AboutMeText;