import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import FlexBoxColumn from "../../../common/box/FlexBoxColumn";
import CustomList from "../../../common/list/CustomList";
import CustomListItem from "../../../common/list/CustomListItem";

const DatabricksItems = [
    {
        title: "Platform Initialization",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Creation" description="Platform initialization, automatization of the workspaces creation using Terraform" />
                    <CustomListItem title="VPC" description="Link Databricks to your already existing VPC" />
                    <CustomListItem title="E2 Workspace" description="Split each root workspace in a specific bucket" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "Inside Workspaces (1)",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Repos" description="Good practices on Git in Databricks, and automate the repos refresh on push in Git" />
                    <CustomListItem title="Groups" description="Specific instance profiles for each business group, with AWS Iam roles attached" />
                    <CustomListItem title="Clusters" description="Pools and cluster policies definition for job and all-purpose by groups" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "Inside Workspaces (2)",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Gitlab integration" description="Manage the Gitlab and allow CI through Databricks" />
                    <CustomListItem title="Databricks SQL" description="Initialize Endpoints and Dashboards for users" />
                    <CustomListItem title="Buckets" description="Creation of all buckets (bronze, silver, gold, import, export) with security associated" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "Monitoring",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Cost" description="Cost monitoring using tags on clusters. Cloudwatch in AWS and parsing in Databricks specific bucket" />
                    <CustomListItem title="Usage" description="Every actions are logged inside a specific bucket that can be read in Databricks itself" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "Good practices",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Cluster" description="Organize trainings for beginners to understand clusters and pools functioning" />
                    <CustomListItem title="Spark" description="Organize hands-on training to introduce beginners to the fundamentals of Spark" />
                    <CustomListItem title="Boiler Plate code" description="Write some wrappers and boilerplate python package to facilitate the onboarding on the platform" />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "And a lot more !",
        children:
            <FlexBoxColumn>
                <Typography variant="h6" fontWeight="bold" color="primary.contrastText" align="center">
                    <br />
                    Don't hesitate to ask if you need something not specified here !
                </Typography>
            </FlexBoxColumn>
    }
]

export default DatabricksItems;