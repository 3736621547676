import React from "react";
import { styled, Box, Divider } from "@mui/material";
import AboutMeText from "./AboutMeText";
import AboutMePhoto from "./AboutMePhoto";
import FlexBoxColumn from "../../common/box/FlexBoxColumn";
import AboutMeEducation from "./AboutMeEducation";

const StyledBox = styled(Box)(({ theme }) => ({
    padding: '3vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}))

const AboutMePresentation = () => {
    return (
        <StyledBox>
            <FlexBoxColumn sx={{
                paddingLeft: "2vw",
                paddingRight: "4vw"
            }}>
                <AboutMeText />
            </FlexBoxColumn>
            <Divider sx={{ borderRightWidth: 3, borderColor: "black", display: { sm: "none", lg: "flex" } }} orientation="vertical" variant="middle" flexItem />
            <FlexBoxColumn sx={{
                paddingLeft: "4vw",
                paddingRight: "2vw"
            }}>
                <AboutMeEducation />
            </FlexBoxColumn>

        </StyledBox>
    )
}

export default AboutMePresentation;
