import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

const MyIconButtonButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.contrastText
}))


const XSNavButton = (props: IconButtonProps) => {
    return (
        <MyIconButtonButton {...props}>
            <MenuIcon />
        </MyIconButtonButton>
    );
};

export default XSNavButton;


