import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { NavContext } from '../../../contexts/NavContext';
import { scrollWithOffset } from '../../../functions/scroll';

interface Props extends ButtonProps {
    handleCloseNavMenu?: () => void;
    navLinkId: string,
    scrollToId: string
}

const StyledNavButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    marginTop: 2,
    marginBottom: 2,
    display: 'block',
    fontWeight: 700,
    fontSize: "1.05em"
}))


const NavButton = ({ navLinkId, scrollToId, handleCloseNavMenu, ...rest }: Props) => {

    const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);

    const handleClick = () => {
        if (handleCloseNavMenu)
            handleCloseNavMenu()
        setActiveNavLinkId(navLinkId)

        const elementToScroll = document.getElementById(scrollToId) as HTMLElement;
        if (navLinkId === "Home")
            scrollWithOffset(elementToScroll, 50)
        else
            scrollWithOffset(elementToScroll, 0)
    }

    return (
        <StyledNavButton  {...rest} id={navLinkId} className={activeNavLinkId === navLinkId ? 'activeClass' : ''} onClick={handleClick}>
            {navLinkId}
        </StyledNavButton>
    );
};


export default NavButton;


