import { Box, Button, List, ListItem, Typography } from "@mui/material";
import React from "react";
import FlexBoxColumn from "../../common/box/FlexBoxColumn";
import SeparatorBox from "../../common/box/SeparatorBox";
import CommonModal from "../../common/modal/CommonModal";

const Enedis = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <FlexBoxColumn sx={{
            justifyContent: "center",
        }}>
            <SeparatorBox sx={{ marginBottom: "10px", borderColor: "secondary.main" }} />
            <Box sx={{
                display: "flex",
                flexGrow: 0,
                flexDirection: "row",
                justifyContent: "space-evenly"
            }}>
                <Typography variant="h5" align="center" fontWeight="bold">
                    Enedis (18 months, 2020-2021), Lyon
                </Typography>
                <Button onClick={handleOpen}>
                    Click for Technical Stack
                </Button>
                <CommonModal open={open}
                    onClose={handleClose}>
                    <FlexBoxColumn sx={{
                        padding: '20px'
                    }}>
                        <Typography variant="h5" color="primary.contrastText" align="center">
                            Technical Stack
                        </Typography>
                        <List sx={{
                            paddingLeft: "3vw",
                            listStyleType: 'disc',
                            pl: 2
                        }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Java 1.8
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Java libraries: SpringBatch, Mockito, Lombok...
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Python 3.6
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Python libraries: yaml, requests, cx_oracle...
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Informatica
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Ansible, Jenkins, Kafka, Grafana
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Database: Oracle 11.2
                                </Typography>
                            </ListItem>
                        </List>
                    </FlexBoxColumn>
                </CommonModal>
            </Box>

            <SeparatorBox sx={{ marginTop: "10px", borderColor: "secondary.main" }} />
            <FlexBoxColumn sx={{
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                width: "85%"
            }}>
                <FlexBoxColumn sx={{
                    paddingTop: "5vh"
                }}>
                    <Typography variant="h6" align="left">
                        <span style={{ fontWeight: 'bold' }}>Context</span> : galaxy of applications to help the deployment of Linky meters. Senior developer to maintain the platform and help SRE team.
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column"
                    }}>
                        <Typography variant="h6" align="left" fontWeight={"bold"} sx={{ paddingTop: "10px" }}>
                            Missions:
                        </Typography>
                        <List sx={{
                            paddingLeft: "3vw",
                            listStyleType: 'disc',
                            pl: 2
                        }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Bug fixes
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Creation of Python utilities for specific needs (data catch-up, data comparison or automation)
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    SQL query optimization
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    In-depth analysis and proposals for batch improvements (Spring and Informatica)
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Creation of a functional metrics management tool to facilitate production monitoring (Python 3.6 for development, Ansible for deployment). Use of REST calls (request library), SSH calls (paramiko library) or SQL queries (cx_Oracle library). The declaration and publication of metrics is done via a Kafka solution connected to a Grafana.
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Presentations and training for future users of the metrics management tool
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography>
                                    Creating Automated Tests for Informatica from Java
                                </Typography>
                            </ListItem>
                        </List>
                    </Box>
                </FlexBoxColumn>
            </FlexBoxColumn>
        </FlexBoxColumn>
    )
}

export default Enedis;