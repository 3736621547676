import { Avatar, Box, Button, Collapse, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import FlexBoxColumn from "../../common/box/FlexBoxColumn";
import SeparatorBox from "../../common/box/SeparatorBox";
import CustomList from "../../common/list/CustomList";
import CustomListItem from "../../common/list/CustomListItem";
import { ArrowCircleDown, FormatListBulletedRounded } from "@mui/icons-material";
import CommonModal from "../../common/modal/CommonModal";

const Navya = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <FlexBoxColumn sx={{
            justifyContent: "center",
        }}>
            <SeparatorBox sx={{ marginBottom: "10px", borderColor: "secondary.main" }} />
            <Box sx={{
                display: "flex",
                flexGrow: 0,
                flexDirection: "row",
                justifyContent: "space-evenly"
            }}>
                <Typography variant="h5" align="center" fontWeight="bold">
                    Navya Group (2 years, 2021-2023), Villeurbanne
                </Typography>
                <Button onClick={handleOpen}>
                    Click for Technical Stack
                </Button>
                <CommonModal open={open}
                    onClose={handleClose}>
                    <FlexBoxColumn sx={{
                        padding: '20px'
                    }}>
                        <Typography variant="h5" color="primary.contrastText" align="center">
                            Technical Stack
                        </Typography>
                        <List sx={{
                            paddingLeft: "3vw",
                            listStyleType: 'disc',
                            pl: 2
                        }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Python 3.8+
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Python libraries: Localstack, Yaml, Pandas...
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Terraform 0.10.2, Ansible
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Databricks, dbx 0.6.3, PySpark 3.2.1
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    Docker
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1" color="primary.contrastText">
                                    AWS Services: S3, Iam Roles, KMS, Redshift, VPC, Security Groups, Datasync, Lambda...
                                </Typography>
                            </ListItem>
                        </List>
                    </FlexBoxColumn>
                </CommonModal>
            </Box>

            <SeparatorBox sx={{ marginTop: "10px", borderColor: "secondary.main" }} />
            <FlexBoxColumn sx={{
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                width: "85%"
            }}>
                <FlexBoxColumn sx={{
                    paddingTop: "5vh"
                }}>
                    <Typography variant="h6" align="left">
                        <span style={{ fontWeight: 'bold' }}>Context</span> : autonomous shuttle. Lead tech data role with the objective of setting up a scalable data architecture.
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "row"
                    }}>
                        <Box sx={{
                            width: "50%"
                        }}>
                            <Typography variant="h6" align="left" fontWeight={"bold"} sx={{ paddingTop: "10px" }}>
                                Data Architect/Data Ops missions:
                            </Typography>
                            <List sx={{
                                paddingLeft: "3vw",
                                listStyleType: 'disc',
                                pl: 2
                            }}>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Benchmark and comparison of different Data platforms (SaaS and others)
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Implementation of the Databricks platform via Terraform on several environments
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Implementation of best practices for Databricks integration in AWS
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Configuration of a proxy via a Nexus
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Management of a gitlab (runners, users, groups, projects...)
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Creation of PySpark boilerplate projects to facilitate the work of the teams and give the best practices
                                    </Typography>
                                </ListItem>
                            </List>
                        </Box>
                        <Box sx={{
                            width: "50%"
                        }}>
                            <Typography variant="h6" align="left" fontWeight={"bold"} sx={{ paddingTop: "10px" }}>
                                Data Engineer missions:
                            </Typography>
                            <List sx={{
                                paddingLeft: "3vw",
                                listStyleType: 'disc',
                                pl: 2
                            }}>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Migration of the old ETL on Databricks to Spark using MySQL and Redshift connectors.
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Adding new data sources as Git, Prometheus, customs...
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Creation and completion of a global data lineage document
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Management of a Redshift with good practices (specific schemas & users, naming convention...). Benchmark of the different encoding algos according to the types.
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Management of a visualization tool (Tableau) with the addition of new data sources (Databricks & Redshift)
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    <Typography>
                                        Study and implementation of a complex pipeline to retrieve and process several hundred GBs of data in a distributed manner (AWS Snowcone, Datasync & Databricks)
                                    </Typography>
                                </ListItem>
                            </List>
                        </Box>

                    </Box>


                </FlexBoxColumn>
            </FlexBoxColumn>
        </FlexBoxColumn>
    )
}

export default Navya;