import React from "react";
import { styled, Box, Typography, Button } from "@mui/material";
import { ReactComponent as LogoSupport } from '../../../assets/images/support.svg';
import BorderedSection from "../../common/box/BorderedSection/BorderedSection";
import InfoButton from "./InfoButton";

const SkillsSupport = () => {
    return (
        <BorderedSection icon={<LogoSupport width={'50px'} />}>
            <Typography variant="h6" color="primary.contrastText" fontWeight="bold" align="center">
                Formation <br /><br />
            </Typography>
            <Typography variant="body1" color="primary.contrastText" align="justify" sx={{
            }}>
                I can train your teams on both technical and support issues. Using communication supports or helping directly on issues.<br /><br />
            </Typography>
        </BorderedSection>
    )
}

export default SkillsSupport;