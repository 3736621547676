import React from 'react';
import { Typography, Box } from '@mui/material';

function Footer() {
    return (
        <Box sx={{
            backgroundColor: "primary.main",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Typography variant='caption' color={'primary.contrastText'}>
                Site design / logo © 2023 ACG - Website optimized for screen wider than 1200 pixels
            </Typography>
        </Box>
    )
}

export default Footer;