import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import { navLinks } from './navLinks';
import XSNavItems from './XSNavItems';


interface Props extends MenuProps {
    handleCloseNavMenu?: () => void
}


const XSNavList = ({ handleCloseNavMenu, ...rest }: Props) => {

    return (
        <Menu id="nav-topbar"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                display: { md: 'block', lg: 'none' },
            }}

            {...rest}
        >
            {
                navLinks.map(({ navLinkId, scrollToId }, idx) => (
                    <XSNavItems key={idx} navLinkId={navLinkId} scrollToId={scrollToId} handleCloseNavMenu={handleCloseNavMenu}>
                        {navLinkId}
                    </XSNavItems>
                ))
            }
        </Menu>
    );
};

export default XSNavList;


