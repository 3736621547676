import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import FlexBoxColumn from "../../../common/box/FlexBoxColumn";
import CustomList from "../../../common/list/CustomList";
import CustomListItem from "../../../common/list/CustomListItem";

const IntegrationItems = [
    {
        title: "Terraform",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Databricks" description="Terraform provider to init workspaces and populate them" />
                    <CustomListItem title="AWS" description="Terraform provider on Redshift, VPC, Subnets, Security Groups, IAM, Buckets ..." />
                </CustomList>
            </FlexBoxColumn >
    },
    {
        title: "Ansible",
        children:
            <FlexBoxColumn>
                <CustomList>
                    <CustomListItem title="Use" description="I know how to use it, modify variables, targets and roles." />
                </CustomList>
            </FlexBoxColumn >
    }
]

export default IntegrationItems;