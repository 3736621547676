import { createTheme, responsiveFontSizes } from '@mui/material';
import orange, { lightBackgroundOrange, topBarOrange } from './colors';

const theme = createTheme({
    palette: {
        primary: {
            main: "#e3762e",
            light: lightBackgroundOrange,
            dark: topBarOrange,
            contrastText: "white",
        }
        ,
        secondary: {
            main: "#000000"
        }
    },
    typography: {
        fontFamily: "Source Code Pro",
        caption: {
            color: "#fcefca"
        }
    }
})

export const customTheme = responsiveFontSizes(theme)