import React from 'react';
import { Box } from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNav } from '../../../hooks/useNav';
import ExperiencesTitle from './ExperiencesTitle';
import ExperiencesCarousel from './ExperiencesCarousel';

const CustomBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up("lg")]: {
        paddingTop: '10vh',
        paddingLeft: '5vw',
        paddingBottom: '1vh',
        paddingRight: '5vw'
    }
}))

const Experiences = () => {

    const aboutMeRef = useNav("Experiences")

    return (
        <CustomBox ref={aboutMeRef} id="experiences">
            <ExperiencesTitle />
            <ExperiencesCarousel />
        </CustomBox>
    )
}



export default Experiences;
