import React from 'react';
import { Container, AppBar, Toolbar } from "@mui/material";

import XSNavButton from './XSNavButton';
import XSNavList from './XSNavList';
import XSNavBox from './XSNavBox';
import Logo from './Logo';
import NavList from './NavList';
import XSFullName from './XSFullName';
import FullName from './FullName';


function TopBar() {

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <AppBar position='sticky' color='transparent' elevation={3}
                sx={{
                    px: { md: 0, lg: '2.5vw' },
                    top: 0,
                    backgroundColor: '#dd6b27'
                }}>
                <Container maxWidth={false}>
                    <Toolbar disableGutters>

                        {/* This box will define the menu icon on the left when screen too small */}
                        <XSNavBox>
                            <XSNavButton onClick={handleOpenNavMenu} />
                            <XSNavList
                                anchorEl={anchorElNav}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                handleCloseNavMenu={handleCloseNavMenu}
                            />
                        </XSNavBox>

                        {/* The following component  will define the logo
                                Display it when screen >= 'sm'
                        */}
                        <Logo />

                        {/* This component will display full name when screen >= 'sm' */}
                        <FullName />
                        <XSFullName />

                        {/* Nav buttons when wide screen*/}
                        <NavList handleCloseNavMenu={handleCloseNavMenu} />


                    </Toolbar>
                </Container>
            </AppBar>
        </>

    )

}

export default TopBar;