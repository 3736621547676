import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledFullName = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    marginRight: 2,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: '0.3rem',
    textDecoration: 'none',
    display: 'none',
    fontWeight: 700,
    [theme.breakpoints.only("xs")]: {
        display: 'flex'
    }
}))


const XSFullName = () => {
    return (
        <StyledFullName variant='h5' noWrap>
            ACG
        </StyledFullName>
    )
}

export default XSFullName;