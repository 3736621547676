import React from "react";
import { Box, styled, Typography } from "@mui/material";

const StyledSkillsBox = styled(Box)(({ theme }) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '2vh',
    flexGrow: 1,
}))

const SkillsTitle = () => {
    return (
        <StyledSkillsBox>
            <Typography variant="h2" sx={{
                fontWeight: 'bold',
                color: "primary.contrastText"
            }}>
                Skills
            </Typography>
        </StyledSkillsBox>
    )
}

export default SkillsTitle;