import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import NavButton from './NavButton';
import { navLinks } from './navLinks';


interface Props extends BoxProps {
    handleCloseNavMenu?: () => void;
}

const StyledBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down("lg")]: {
        display: 'none'
    }
}))

const NavList = ({ handleCloseNavMenu, ...rest }: Props) => {

    return (
        <StyledBox {...rest}>
            {
                navLinks.map(({ navLinkId, scrollToId }, idx) => (
                    <NavButton key={idx} navLinkId={navLinkId} scrollToId={scrollToId} handleCloseNavMenu={handleCloseNavMenu}>
                        {navLinkId}
                    </NavButton>
                ))
            }

        </StyledBox>
    )
}

export default NavList;