import React from "react";
import { ListItemButton, ListItemButtonProps, ListItemText, styled } from "@mui/material";

interface Props extends ListItemButtonProps {
    link: string,
    description: string
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    ':hover': {
        backgroundColor: theme.palette.primary.main,
    },
})) as typeof ListItemButton;

const CustomListItemTooltip = ({ link, description, ...rest }: Props) => {

    return (
        <StyledListItemButton component="a" href={link} >
            {description && <ListItemText primary={description} primaryTypographyProps={{
                fontSize: '1.2em'
            }} />}

        </StyledListItemButton>
    )
}

export default CustomListItemTooltip;