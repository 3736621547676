import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { RandomReveal } from 'react-random-reveal';

const StyledFullName = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontFamily: 'Source Code Pro',
    marginRight: 2,
    flexGrow: 1,
    letterSpacing: '0.005rem',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only("xs")]: {
        display: 'none'
    }
}))


const FullName = () => {
    return (
        <StyledFullName variant='h5'>
            Ar
            <div style={{
                color: 'black'
            }}>
                <RandomReveal
                    isPlaying
                    duration={4}
                    revealDuration={1.6}
                    characters="th"
                    onComplete={() => ({ shouldRepeat: true, delay: 20 })}
                    revealEasing="random"
                />
            </div>

            ur Clerc-Gherardi
        </StyledFullName>
    )
}



export default FullName;