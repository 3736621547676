import React from "react";
import { styled, Box, Typography, Button } from "@mui/material";
import { ReactComponent as LogoSpark } from '../../../assets/images/spark.svg';
import BorderedSection from "../../common/box/BorderedSection/BorderedSection";
import { Height } from "@mui/icons-material";
import InfoButton from "./InfoButton";
import CommonModal from "../../common/modal/CommonModal";
import FlexBoxColumn from "../../common/box/FlexBoxColumn";
import Carousel from "react-material-ui-carousel";
import SeparatorBox from "../../common/box/SeparatorBox";
import ETLItems from "./carousel/ETLItems";

const SkillsSpark = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <BorderedSection icon={<LogoSpark width={'80px'} />}>
            <Typography variant="h6" color="primary.contrastText" fontWeight="bold" align="center">
                A "Swiss Knife" of Data <br /><br />
            </Typography>
            <Typography variant="body1" color="primary.contrastText" align="justify" sx={{
            }}>
                ELT/ETL pipelines are my core business. I can help you transform using Spark and Python/Java all kind of data (structured, semi-structured and unstructured). <br /><br />
            </Typography>
            <InfoButton onClick={handleOpen} />
            <CommonModal
                open={open}
                onClose={handleClose}
            >
                <FlexBoxColumn sx={{
                    padding: '20px',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        flexGrow: 0
                    }}><Typography fontFamily="Source Code Pro" variant="h4" color="primary.contrastText">
                            Good knowledge on the following topics
                        </Typography>
                    </Box>

                    <FlexBoxColumn sx={{
                        padding: '5px'
                    }}>
                        <Carousel
                            animation="slide"
                            swipe={false}
                            navButtonsAlwaysVisible
                            autoPlay={false}
                            height="100%"
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: 'column',
                                margin: "5px",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            {
                                ETLItems.map(({ title, children }, i) =>
                                    <FlexBoxColumn sx={{
                                        height: '100%',
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <FlexBoxColumn sx={{
                                            width: "80%",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SeparatorBox sx={{ marginBottom: "10px" }} />
                                            <Typography variant="h6" align="center" color="primary.contrastText" fontWeight="bold">
                                                {title}
                                            </Typography>
                                            {children}
                                            <SeparatorBox sx={{ marginTop: "10px" }} />
                                        </FlexBoxColumn>

                                    </FlexBoxColumn>
                                )
                            }
                        </Carousel>
                    </FlexBoxColumn>

                </FlexBoxColumn>
            </CommonModal>

        </BorderedSection>

    )
}

export default SkillsSpark;